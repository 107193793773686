<template>
  <div class="card card-body filterItem" ref="searchItem">
    <img :src="`${imageDomain}/assets/img/hot-offer.png`" alt="hot-offer" v-if="isShowHotelHotIcon" class="hot-offer-icon" width="30" height="30"/>

    <div class="row" v-if="type==='booking-status'">
      <div class="col-md-12 d-flex flex-column position-static main-desc">
        <h4 class="title" :class="{'d-flex': device==='desktop'}">
          <p class="m-0" style="line-height: 1.1rem;">{{ hotel.hotelName }}</p>
        </h4>
        <div class="d-flex">
          <b-form-rating class="ui rating" variant="warning" inline :value="hotel.hotelGrade" readonly locale="he"></b-form-rating>
        </div>
        <p class="item-duration" :dir="lang==='he'? 'rtl': 'ltr'">
          {{`${hotel.duration} ${$t("home.nights")} / ${Number(hotel.duration)+1} ${$t("home.days")}`}}
        </p>
        <h5 class="d-block">
          <i class="far fa-calendar-alt"></i>
          <strong>{{ $t('search-result.trip-duration') }}:</strong> {{ `${hotel.checkIn} - ${hotel.checkOut}` }}
        </h5>
        <h5 class="my-1 mx-0">
          <img class="icon ml-1" :src="`${imageDomain}/assets/img/deal_-_basis.png`" alt="deal-basis" width="15" height="15"/>
          <span>{{ hotel.basisName }}</span>
        </h5>
      </div>
    </div>

    <div class="row" v-else>
      <div class="imgRoom col-12 col-md-9">
        <div class="row no-gutters flex-md-row">
          <div class="col-md-3 col-5 sportthumble d-lg-block">
            <div class="select-fav" style="z-index:10;" @click="() => showModal('gallery')">
              <input type="checkbox" class="custom-control-input" :id="`fab-${itemIndex}`"/>
              <label class="fav" :for="`fab-${itemIndex}`"></label>
            </div>
            <div class="share-button" @click="() => showModal('share')">
              <svg xmlns="http://www.w3.org/2000/svg" class="share-icon" viewBox="0 0 512 512">
                <path d="M378,324a69.78,69.78,0,0,0-48.83,19.91L202,272.41a69.68,69.68,0,0,0,0-32.82l127.13-71.5A69.76,69.76,0,1,0,308.87,129l-130.13,73.2a70,70,0,1,0,0,107.56L308.87,383A70,70,0,1,0,378,324Z" fill="#28a745"></path></svg>
            </div>
            <b-img v-bind="mainProps" :src="`${thumbnailImage}?speedsize=w_600`" :alt="hotelName" width="200" height="210"
            @click="() => showModal('gallery')" :onerror="`this.src='${imageDomain}/assets/img/has_no_picture.jpg?speedsize=w_600'`"></b-img>
          </div>
          <div class="col-md-9 col-7 pr-md-4 d-flex flex-column position-static mt-5 main-desc">
            <h4 class="title" :class="{'d-flex': device==='desktop'}">
              <p class="m-0" style="line-height: 1.1rem;">{{ hotelName }}</p>
              <!-- <b-badge :variant="hotel.status === 'OK' ? 'success' : 'danger'" >{{ hotel.status }}</b-badge> -->
              <custom-tooltip :content="itineraryProps" v-if="categoryName === 'Organize_tour_packages'" from="search-result"/>
              <b-badge v-if="categoryName !== 'Organize_tour_packages' && hotel.status === 'OK'" variant="success"
                class="align-self-center mr-3"
                :class="{'mobile-position': device==='mobile'}">
                {{$t("search-result.immediate-approval")}}
              </b-badge>
              <b-badge v-if="hotel.hotelType === 'YOUNG'" variant="success" class="align-self-center mr-3">חבילת צעירים</b-badge>
            </h4>
            <div class="d-flex" v-if="grade>2 && categoryName !== 'Organize_tour_packages'" >
              <b-form-rating class="ui rating" variant="warning" inline :value="grade" readonly locale="he"></b-form-rating>
            </div>
            <p class="item-duration" :dir="lang==='he'? 'rtl': 'ltr'">
              {{`${hotel.duration} ${$t("home.nights")} / ${Number(hotel.duration)+1} ${$t("home.days")}`}}
            </p>
            <h5 class="d-block" v-if="device==='desktop'">
              <i class="far fa-calendar-alt"></i>
              <strong>{{ $t('search-result.trip-duration') }}:</strong> {{ tripDuration }}
            </h5>
            <h5 class="my-1 mx-0 d-flex" v-else>
              <img class="icon ml-2" :src="`${imageDomain}/assets/img/calendar.png`" alt="calendar" width="25" height="25"/>
              <div>
                <p>{{ formattedCheckIn }}</p>
                <p>{{ formattedCheckOut }}</p>
              </div>
            </h5>
            <h5 v-if="categoryName !== 'Organize_tour_packages'" class="my-1 mx-0">
              <strong v-if="device==='desktop'">{{ $t('search-result.packages') }}:</strong>
              <img v-else class="icon ml-1" src="assets/img/check.png" style="vertical-align: baseline;" alt="check" width="16" height="16"/>
              {{$t('search-result.flight')}} + {{$t('search-result.hotel')}}
              <span v-if="hotel.supplement.findIndex(d => d.suppId === 49) > -1"> + {{$t('search-result.transfers')}}</span>
            </h5>
            <h5 v-if="device==='mobile'" class="my-1 mx-0">
              <img class="icon ml-1" :src="`${imageDomain}/assets/img/deal_-_basis.png`" alt="deal-basis" width="15" height="15"/>
              <span v-if="categoryName === 'vacation_pack' || categoryName === 'Organize_tour_packages'">{{ basisName }}</span>
            </h5>
            <div :class="['itemTags', device !== 'mobile' ? 'd-flex': '']" v-if="categoryName === 'Organize_tour_packages' && fac && fac.length">
              <div class="">
                <li class="list-inline-item">
                  <div class="text-muted">
                    {{ $t('search-result.hotel-facilities') }}:
                  </div>
                </li>
              </div>
              <div class="col-9">
                <ul class="list-inline mb-auto" >
                  <li class="list-inline-item" v-for="(item, inx) in fac" :key="inx">
                    <span class="badge badge-pill badge-primary">{{ getFacName(item) }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <ul class="list-inline mb-auto itemTags" v-if="categoryName === 'Organize_tour_packages'">
              <li class="list-inline-item">
                <div class="text-muted">
                  {{ $t('search-result.tour-highlights') }}:
                </div>
              </li>
              <li class="list-inline-item">
                <span class="badge badge-pill badge-primary" v-html="hotel.translations && hotel.translations.desc[lang] ? hotel.translations.desc: hotel.desc"></span>
              </li>
              <li class="list-inline-item">
                <span class="badge badge-pill badge-primary">{{ $t('search-result.hebrew-speaking-guide') }}</span>
              </li>
            </ul> -->

            <!-- <p class="mb-0 mt-3" v-if="categoryName === 'Organize_tour_packages'">
              <i class="far fa-calendar-alt"></i>{{ $t('search-result.possible-dates') }}
            </p> -->
            <b-link :to="productQuery" v-if="device !== 'mobile'" class="btn btn-primary btn-selectRoom">
              <span @click="storeLocation">{{ $t('search-result.order-now') }}</span>
            </b-link>
          </div>
        </div>
      </div>
      <div :class="['actionRoom', 'col-12', 'col-md-3', !isOrganizeTour ? 'flex-column': '']">
        <div class="mb-auto mobile-facilities itemTags" v-if="isOrganizeTour && fac && fac.length">
          <div class="text-muted text-right mb-2">
            {{ $t('search-result.hotel-facilities') }}:
          </div>
          <ul class="" >
            <li class="list-inline-item" v-for="(item, inx) in fac" :key="inx">
              <span class="badge badge-pill badge-primary">{{ getFacName(item) }}</span>
            </li>
          </ul>
        </div>
        <!-- <div :class="['pb-3', !isOrganizeTour ? '' : 'm-auto']" v-if="device !== 'mobile' && !isOrganizeTour"> -->
        <div :class="['m-auto', isShowHotelHotIcon ? 'pt-5' : '']" v-if="device !== 'mobile' && !isOrganizeTour">

          <p class="text-center mt-4 mb-0" v-if="!isShowHotelHotIcon || isAgencyLogin">

            <span v-if="isOdysseySite && hotel.cheapestForAgents" class="odyssey-prices">
              <div><b>{{ `${$t('search-result.online-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.priceAverage)}` }}</b></div>
              <div v-if="hotel.cheapestForAgents.priceBClient"><b>{{`${$t('search-result.bclient-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.cheapestForAgents.priceBClient)}`}}</b></div>
              <b v-if="hotel.cheapestForAgents.price">{{`${$t('search-result.full-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.cheapestForAgents.price)}`}}</b>
            </span>

            <b v-else>{{ getPriceWithSymbol(  hotel.cc,  hotel.priceAverage) }}</b>
          </p>
          <p class="text-center large mt-4 mb-0" v-else>
            <b>{{ getPriceWithSymbol(  hotel.cc,  discountedInfo.discountedPrice.price_average) }}</b>
            <s>{{ getPriceWithSymbol(hotel.cc, hotel.originalPrices.price_average || (Number(hotel.priceAverage) * 1.1).toFixed(0)) }}</s>
          </p>
          <p class="large text-center mb-2" v-if="isOdysseySite">{{ $t('search-result.agent-total-per-person') }}</p>
          <p class="large text-center mb-2" v-else>{{ $t('search-result.total-per-person') }}</p>
          <p class="large text-center" ><label>{{ $t('search-result.total')  + ' - ' }}</label> {{ getPriceWithSymbol(  hotel.cc,  totalPrice ) }}</p>
          <b-link :to="productQuery" class="btn btn-primary btn-selectRoom">
            <span @click="storeLocation">{{ $t('search-result.order-now') }}</span>
          </b-link>
        </div>
        <!-- <div :class="['pb-3', !isOrganizeTour ? '' : 'm-auto']" v-else> -->
        <div :class="['m-auto']" v-else>
          <p class="text-center mt-2" v-if="!isShowHotelHotIcon || isAgencyLogin">

            <span v-if="isOdysseySite && hotel.cheapestForAgents" class="odyssey-prices">
              <div><b>{{ `${$t('search-result.online-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.priceAverage)}` }}</b></div>
              <div v-if="hotel.cheapestForAgents.priceBClient"><b>{{`${$t('search-result.bclient-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.cheapestForAgents.priceBClient)}`}}</b></div>
              <b v-if="hotel.cheapestForAgents.price">{{`${$t('search-result.full-price')} - ${getPriceWithSymbol(  hotel.cc,  hotel.cheapestForAgents.price)}`}}</b>
            </span>

            <b v-else>{{ getPriceWithSymbol(  hotel.cc,  hotel.priceAverage ) }}</b>
          </p>
          <p class="text-center large mt-2" v-else>
            <b>{{ getPriceWithSymbol(  hotel.cc,  discountedInfo.discountedPrice.price_average ) }}</b>
            <s>{{ getPriceWithSymbol(hotel.cc, hotel.originalPrices.price_average || (Number(hotel.priceAverage) * 1.1).toFixed(0)) }}</s>
          </p>
          <p class="large text-center" v-if="isOdysseySite">{{ $t('search-result.agent-total-per-person') }}</p>
          <p class="large text-center" v-else>{{ $t('search-result.total-per-person') }}</p>
          <p class="large mt-2 text-center" ><label>{{ $t('search-result.total') + ' - ' }}</label> {{ getPriceWithSymbol(  hotel.cc,  totalPrice ) }}</p>
          <b-link :to="productQuery" class="btn btn-primary btn-selectRoom">
            <span @click="storeLocation">{{ $t('search-result.order-now') }}</span>
          </b-link>
        </div>
      </div>
    </div>
    <div class="table-responsive" v-if="type!=='booking-status'">
      <table class="table table-striped vacation-tbl">
        <thead>
          <tr>
            <th>
              <i class="fas fa-hand-holding-usd"></i><br />
              <span v-if="lang==='he'">{{ $t('search-result.price-per-passenger') }}/{{ $t('search-result.total') }}</span>
              <span v-else>{{ $t('search-result.total') }}/{{ $t('search-result.price-per-passenger') }}</span>
            </th>
            <th>
              <i class="fas fa-hotel"></i><br />{{ $t('search-result.accommodation') }}
            </th>
            <th>
              <img :src="`${imageDomain}/assets/img/icon8.png`" alt="icon8" width="25" height="25"/><br />
              {{ $t('search-result.vacation') }}
            </th>
            <th>
              <i class="fas fa-moon"></i><br />{{ $t('search-result.number-of-nights') }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t('search-result.output') }}
            </th>
            <th>
              <i class="fas fa-calendar-week"></i><br />{{ $t('search-result.return') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ getPriceWithSymbol(  hotel.cc,  flightDiscounted ? discountedInfo.discountedPrice.price_average : hotel.priceAverage ) }} / {{ getPriceWithSymbol(  hotel.cc,  totalPrice ) }}
            </td>
            <td>
              <span v-if="categoryName === 'vacation_pack' || categoryName === 'Organize_tour_packages'">{{ basisName }}</span>
            </td>
            <td>
              <span v-if="categoryName === 'vacation_pack'">{{ $t('search-result.leisure-vacation') }}</span>
              <span v-else-if="categoryName === 'Organize_tour_packages'">{{ $t('search-result.organize-tour') }}</span>
            </td>
            <td>{{ hotel.duration }}</td>
            <td>{{ outputDateTime }}</td>
            <td>{{ returnDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="select-flight"  v-if="!isOrganizeTour">
      <!-- <div class="selectflhtbtn" id="selectflhtbtn" v-b-toggle="`flightfltr-${hotel.PCK_ID}-${hotel.hotelId}`"><img :src="`${imageDomain}/assets/img/flight.png`" alt="flight">Select Flight</div> -->
      <div v-if="flights.length>0 && isOdysseySite" class="selectflhtbtn" id="selectflhtbtn" v-b-toggle="`filter-cont-${itemIndex}`">
        <img :src="`${imageDomain}/assets/img/flight.png`" alt="flight" width="25" height="25"/>{{ $t('search-result.select-flight') }}
      </div>
      <b-collapse class="flightfltr" :id="`flightfltr-${itemIndex}`" v-if="isOdysseySite">
        <div class="row">
          <div class="form-group col-lg-4 col-md-4">
            <label>{{ $t('search-result.departure') }}</label>
            <select class="form-control" v-model="dFlightInx">
              <option v-for="(item, inx) in outputDateTimes" :key="inx" :value="inx">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-4">
            <label>{{ $t('search-result.arrival') }}</label>
            <select class="form-control" v-model="aFlightInx">
              <option v-for="(item, inx) in returnDateTimes" :key="inx" :value="inx">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-4 col-md-4">
            <input type="submit" value="Select" id="fltr" v-b-toggle="`filter-cont-${itemIndex}`"/>
          </div>
        </div>
      </b-collapse>
    </div>

    <b-collapse class="filter-cont dVacation-pack" :id="`filter-cont-${itemIndex}`" v-if="isOdysseySite">
      <div class="filterItem small" v-for="(item, inx) in flights" :key="inx" :class="{ 'hot-flight': isShowFlightHotIcon }">
        <div @click="changeFlightIndex(inx)">
          <div class='row mr-3'>
            <p class="ticketLeft mb-1">
              {{ Math.min(item.avl1, item.avl2) > 8 ? '' : `${$t('search-result.number-seats-left')}: ${Math.min(item.avl1,item.avl2)}` }}
            </p>
          </div>
          <div >
            <div class='row col-12 col-md-12 p-0'>
              <div class="col-5 col-md-5 border-md-right flight-img">
                <div class="ticket-topPart">
                  <h6>{{ $t('search-result.departure-flight') }}</h6>
                  <div class="flight-logo">
                    <img v-if="!item.FlightDetail[0].airlineNotConfirmed" :src="getLogoInfo(item, 'out')" alt="airline-not-confirmed" class="img-fluid" />
                  </div>
                  <div>
                    <span class="flightName d-block">{{ convertDateFormat(item.FlightDetail[0].FL_Date) }}</span>
                    <span class="flightName d-block">{{ item.FlightDetail[0].airlineInfo.desc1 }}</span>
                    <span class="flightName d-block">{{ (item.FlightDetail[0].airlineInfo.code || item.FlightDetail[0].FL_AIRLINE) + '-'+ item.FlightDetail[0].FL_Flt_Number }}</span>
                  </div>
                </div>
              </div>
              <div class="col-7 col-md-7 p-0">
                <div class="ticket-topPart-big">
                  <div class="col-md-12 d-flex justify-content-between">
                    <div class="box">
                      <span class="d-block">{{ hotel.translations.flightDestinationName[item.FlightDetail[0].FL_From_Route][lang] || item.FlightDetail[0].FL_From_Route }}</span>
                      <span class="time">{{ item.FlightDetail[0].FL_Dep_Hour }}</span>
                      <span class="d-block">{{ getWeek(item.FlightDetail[0].FL_Date) }}</span>
                    </div>
                    <div class="box-middle">
                      <img class="img-fluid" :src="`${imageDomain}/assets/img/plane-from.png`" alt="plan-from" width="97" height="27"/>
                      <div class="d-flex justify-content-between mt-3 w-100">
                        <i class="customIcon icon-bag" v-if="getMaxWeight(item, 'out', 'bag')">
                          <span class="count">{{ getMaxWeight(item, 'out', 'bag') }}</span>
                        </i>
                        <i class="customIcon icon-no-bag" v-else>
                          <span class="count">{{ getMaxWeight(item, 'out', 'bag') }}</span>
                        </i>
                        <i class="customIcon icon-luggage">
                          <span class="count">{{ getMaxWeight(item, 'out', 'luggage') }}</span>
                        </i>
                      </div>
                    </div>
                    <div class="box">
                      <span class="d-block">{{ hotel.translations.flightDestinationName[item.FlightDetail[0].FL_To_Route][lang] }}</span>
                      <span class="time">{{ item.FlightDetail[0].FL_Arrv_Hour }}</span>
                      <span class="d-block">{{ getWeek(item.FlightDetail[0].landingDate) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="remark mb-2 mr-4">
              <p>{{ item.FlightDetail[0].Authorization_msg }}</p>
            </div>
          </div>
          <b-form-radio v-model="flightInx" :value="inx" class="flight-select" size="lg"></b-form-radio>
          <div>
            <div class='row col-12 col-md-12 p-0'>
              <div class="col-5 col-md-5 border-md-right flight-img">
                <div class="ticket-bottomPart">
                  <h6>{{ $t('search-result.arrival-flight') }}</h6>
                  <div class="flight-logo">
                    <img v-if="!item.FlightDetail[1].airlineNotConfirmed" :src="getLogoInfo(item, 'in')" alt="airline-not-confirmed" class="img-fluid" />
                  </div>
                  <div>
                    <span class="flightName d-block">{{ convertDateFormat(item.FlightDetail[1].FL_Date) }}</span>
                    <span class="flightName d-block">{{ item.FlightDetail[1].airlineInfo.desc1 }}</span>
                    <span class="flightName d-block">{{ (item.FlightDetail[1].airlineInfo.code || item.FlightDetail[1].FL_AIRLINE) + '-'+ item.FlightDetail[1].FL_Flt_Number }}</span>
                  </div>
                </div>
              </div>
              <div class="col-7 col-md-7 p-0">
                <div class="ticket-bottomPart-big">
                  <div class="col-md-12 d-flex justify-content-between">
                    <div class="box">
                      <span class="d-block">{{ hotel.translations.flightDestinationName[item.FlightDetail[1].FL_To_Route][lang] }}</span>
                      <span class="time">{{ item.FlightDetail[1].FL_Arrv_Hour }}</span>
                      <span class="d-block">{{ getWeek(item.FlightDetail[1].landingDate) }}</span>
                    </div>
                    <div class="box-middle">
                      <img class="img-fluid" :src="`${imageDomain}/assets/img/plane-to.png`" alt="plan-to" width="97" height="27"/>
                      <div class="d-flex justify-content-between mt-3 w-100">
                        <i class="customIcon icon-bag">
                          <span class="count">{{ getMaxWeight(item, 'in', 'bag') }}</span>
                        </i>
                        <i class="customIcon icon-luggage">
                          <span class="count">{{ getMaxWeight(item, 'in', 'luggage') }}</span>
                        </i>
                      </div>
                    </div>
                    <div class="box">
                      <span class="d-block">{{ hotel.translations.flightDestinationName[item.FlightDetail[1].FL_From_Route][lang] }}</span>
                      <span class="time">{{ item.FlightDetail[1].FL_Dep_Hour }}</span>
                      <span class="d-block">{{ getWeek(item.FlightDetail[1].FL_Date) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="remark mr-4">
              <p>{{ item.FlightDetail[1].Authorization_msg }}</p>
            </div>
          </div>

        </div>
      </div>
    </b-collapse>
    <b-link :to="productQuery" class="btn btn-primary btn-selectRoom" v-if="device === 'mobile' && type!=='booking-status'">
      <span @click="storeLocation">{{ $t('search-result.order-now') }}</span>
    </b-link>
  </div>
</template>

<script>
import {
  BCollapse,
  VBToggle,
  BFormRating,
  BImg,
  BBadge,
  BLink,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'FilterItem',
  mixins: [gMixin, imageUrlMixin],
  components: {
    BCollapse,
    BFormRating,
    BImg,
    BBadge,
    BLink,
    CustomTooltip: () => import('@/components/atoms/CustomTooltip'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    categoryName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
      destinationImages: 'GET_DESTINATION_IMAGES',
      device: 'GET_DEVICE',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      searchItemLocation: 'GET_SEARCH_ITEM_LOCATION',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    discountFlightInfo() {
      const flight = this.hotel.flights[0];
      return this.hotel.discountedFlights.find((item) => (item.flightsId === `${flight.fl_id1}${flight.fl_id2}`));
    },
    isShowHotelHotIcon() {
      return (this.hotelDiscount && this.hotel.matchingDiscountRule?.hotelId !== null) || this.isShowFlightHotIcon;
    },
    isShowFlightHotIcon() {
      return this.flightDiscounted && this.discountFlightInfo?.matchingDiscountRule?.hotelId !== null;
    },
    isOrganizeTour() {
      return this.category.code === 'Organize_tour_packages';
    },
    destinationImage() {
      const { dest } = this.$route.query;
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      const url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : '';
      return url;
    },
    marketerId() {
      const { marketerId } = this.$route.query;
      return marketerId;
    },
    itineraryProps() {
      return {
        destCode: this.hotel.destCode,
        itinerary: this.hotel.Itinerary,
      };
    },
    hotelName() {
      const { hotel } = this;
      // const { hotel, lang } = this;
      // return hotel?.translations?.hotelName?.[lang] || hotel?.translations?.hotelName?.en || hotel.hotelName;
      return hotel?.translations?.hotelName?.en || hotel.hotelName;
    },
    basisName() {
      return this.hotel?.translations?.basisName?.[this.lang] ?? this.hotel.basisName;
    },
    thumbnailImage() {
      if (!this.hotel.hotelImages || (this.hotel?.hotelImages?.gallery && !this.hotel.hotelImages?.gallery?.length)) {
        return this.hotel.adminHotelImageUrl || this.hotel.imgMainUrl || `${this.imageDomain}/assets/img/has_no_picture.jpg`;
      }
      return this.hotel.hotelImages?.gallery?.[this.itemIndex % (this.hotel.hotelImages?.gallery?.length || 1)] || null;
    },
    productQuery() {
      const body = {
        packId: this.hotel.PCK_ID,
        laId: this.hotel.hotelId,
        dateFrom: this.flights[this.flightInx]?.FlightDetail[0]?.FL_Date,
        flights: `${this.flights[this.flightInx]?.FlightDetail[0]?.FL_ID}${this.flights[this.flightInx]?.FlightDetail[1]?.FL_ID}`,
        adult: this.query.adult,
        child: this.query.child,
        infant: this.query.infant,
        categoryId: this.query.categoryId,
        roomType: this.hotel.Room_Type,
        roomClass: this.hotel.Room_Class,
        discount: this.flightDiscounted ? 1 : 0,
        // discountPercent: this.hotel.discountPercent,
      };
      if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
      if (this.marketerId) body.marketerId = this.marketerId;
      if (this.isOdysseySite) body.odyAgentCode = this.odyAgentCode;
      if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';
      return `/product-page?${new URLSearchParams(body).toString()}`;
    },
    durationDateFormat() {
      return this.device === 'desktop' ? 'DD/MM/YY dddd' : 'dddd - DD/MM/YY';
    },
    formattedCheckIn() {
      const { checkIn, durationDateFormat } = this;
      return this.getDate(checkIn.format(), durationDateFormat);
    },
    formattedCheckOut() {
      const { checkOut, durationDateFormat } = this;
      return this.getDate(checkOut.format(), durationDateFormat);
    },
    tripDuration() {
      const { formattedCheckIn, formattedCheckOut } = this;
      return `${formattedCheckIn} - ${formattedCheckOut}`;
    },
  },
  data() {
    return {
      grade: 0,
      flights: [],
      outputDateTimes: [],
      returnDateTimes: [],
      fac: [],
      totalPrice: 0,
      mainProps: {
        // fluidGrow: true,
        // blank: true,
        // blankColor: '#bbb',
        class: 'img-fluid rounded thumb-image',
      },
      outputDateTime: '',
      returnDateTime: '',
      dFlightInx: 0,
      aFlightInx: 0,
      flightInx: 0,
      checkIn: '',
      checkOut: '',
      query: this.$route.query,

      flightDiscounted: false,
      hotelDiscount: false,
      discountedInfo: null,
      refObject: null,
    };
  },
  watch: {
    lang() {
      this.checkIn = dayjs(this.flights[this.flightInx]?.FlightDetail[0]?.FL_Date).add(Number(this.hotel?.hotel_shift), 'day');
      this.checkOut = this.checkIn.add(Number(this.hotel?.duration), 'day');
    },
    flightInx() {
      this.outputDateTime = this.outputDateTimes[this.flightInx];
      this.returnDateTime = this.returnDateTimes[this.flightInx];
      this.checkIn = this.flights[this.flightInx]?.FlightDetail[0]?.FL_Date;
      this.checkOut = this.flights[this.flightInx]?.FlightDetail[1]?.FL_Date;
    },
    hotel() {
      this.setHotelValues();
    },
  },
  created() {
    this.setHotelValues();
  },
  mounted() {
    if (this.searchItemLocation?.index === this.itemIndex) {
      setTimeout(() => {
        document.querySelector('.st-content').scrollTo(0, this.searchItemLocation.offset);
      }, 100);
    }
    this.refObjectTop = this.$refs.searchItem?.offsetTop || 0;
  },
  methods: {
    getMaxWeight(pFlight, pType, pPackageType) {
      let weight = 0;
      if (pType === 'out') {
        if (pPackageType === 'bag') {
          weight = +pFlight.FlightDetail[0]?.FL_Max_Weight_Trolley;
        } else {
          weight = pFlight.FlightDetail[0]?.FL_Max_Weight;
        }
      } else if (pPackageType === 'bag') {
        weight = +pFlight.FlightDetail[1]?.FL_Max_Weight_Trolley;
      } else {
        weight = pFlight.FlightDetail[1]?.FL_Max_Weight;
      }
      return weight;
    },
    getLogoInfo(pFlight, pType) {
      let url = pType === 'out' ? `${this.imageDomain}/assets/img/bulgeri.jpg` : `${this.imageDomain}/assets/img/blueair.jpg`;
      if (pType === 'out') {
        url = pFlight.FlightDetail[0]?.airlineInfo?.desc2 || url;
      } else {
        url = pFlight.FlightDetail[1]?.airlineInfo?.desc2 || url;
      }
      return url;
    },
    setHotelValues() {
      if (this.type === 'booking-status') return;
      this.grade = this.hotel.grade ? +(this.hotel.grade.substr(0, 1)) : 0;
      this.fac = this.hotel.fac;// [];
      // if (this.hotel && this.hotel.fac) {
      //   this.fac = this.hotel.fac.filter(
      //     (item, inx) => [0, 3, 6, 9, 15, 17, 22].indexOf(inx) > -1,
      //   );
      // }

      this.flights = [];
      this.outputDateTimes = [];
      this.returnDateTimes = [];
      if (this.hotel) {
        // Hotel discounding
        this.hotelDiscount = !!this.hotel.discountPercent && parseFloat(this.hotel.discountPercent) >= 5;
        if (this.hotelDiscount) {
          this.discountedInfo = {
            discountedPrice: {
              price_average: this.hotel.priceAverage,
              priceInf: this.hotel.priceInf,
            },
          };
        }

        // Flight discounting
        this.flightDiscounted = this.hotel.flightDiscounted;
        this.flights = [...this.hotel.flights];
        if (this.flightDiscounted) {
          this.discountedInfo = { ...this.discountFlightInfo };
        }

        this.flights.forEach((flightItem) => {
          this.outputDateTimes.push(`${this.getDate(flightItem.FlightDetail[0]?.FL_Date, 'dddd DD/MM/YY')} ${flightItem.FlightDetail[0]?.FL_Dep_Hour} `);
          this.returnDateTimes.push(`${this.getDate(flightItem.FlightDetail[1]?.FL_Date, 'dddd DD/MM/YY')} ${flightItem.FlightDetail[1]?.FL_Dep_Hour} `);
        });
        this.totalPrice = this.flightDiscounted ? this.discountedInfo.discountedPrice?.price : this.hotel.Price;
      }

      this.outputDateTime = this.outputDateTimes[this.flightInx];
      this.returnDateTime = this.returnDateTimes[this.flightInx];
      this.checkIn = dayjs(this.flights[this.flightInx]?.FlightDetail[0]?.FL_Date).add(Number(this.hotel?.hotel_shift), 'day');
      this.checkOut = this.checkIn.add(Number(this.hotel?.duration), 'day');
    },
    getFacName(pItem) {
      return this.hotel.translations?.fac?.[pItem.code]?.[this.lang] ?? pItem.code;
    },
    getDate(pStrDate, pFormat) {
      dayjs.locale(this.lang);
      let format = 'DD/MM';
      if (pFormat !== undefined && pFormat !== '') format = pFormat;
      return dayjs(pStrDate).format(format);
    },
    getWeek(str) {
      const week = [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getFlightTime(tim) {
      return tim.split(':');
    },
    changeFlightIndex(index) {
      this.flightInx = index;
    },
    convertDateFormat(pDate) {
      const format = 'DD-MM-YYYY';
      return dayjs(pDate).format(format);
    },
    showModal(type) {
      this.$emit('showGalleryShare', {
        index: this.itemIndex,
        link: this.productQuery,
        type,
        hotel: this.hotel.hotelName,
        image: this.thumbnailImage,
        rate: this.getPriceWithSymbol(this.hotel.cc, this.hotel.priceAverage),
      });
    },
    storeLocation() {
      this.$store.commit('SET_SEARCH_ITEM_LOCATION', { offset: this.refObjectTop, index: this.itemIndex });
    },
  },
};
</script>

<style scoped>
.mobile-position{
  position: absolute;
  top: 0.7rem;
  transform: translateX(6rem);
  font-size: 60%;
}
img.icon {
  object-fit: contain;
  width: 1rem;
}
.thumb-image{
  transition: 0.5s all;
  cursor: pointer;
}
.thumb-image:hover{
  opacity: 0.5;
}
.sportspage_area .package-organize .filterItem .title {
  font-size: 20px;
}
.itemTags{
  display: none;
}
.hot-flight {
  background-image: url('https://ecdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/hot-offer-big.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 50px 50px;
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -8px;
  left: -8px;
  position: absolute;
}
.dVacation-pack .ticket-topPart-big .box,
.dVacation-pack .ticket-bottomPart-big .box {
  min-width: 83px;
}
.flight-img{
  padding-right: 80px;
}
.ticket-topPart-big, .ticket-bottomPart-big{
  padding-left: 50px;
  padding-right: 50px;
}
.remark{
  margin-right: 45px !important;
}
.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}
.ui.rating {
  padding-right: 0;
  font-size: 15px;
}
.itemTags {
  line-height: 30px;
  padding: 0;
}
.table td {
  text-align: center;
}
.flight-select {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
}
.filterItem {
  position: relative;
  padding: 5px;
  box-shadow: none;
}
.sportspage_area .filterItem .actionRoom .large {
  font-size: 18px !important;
  font-weight: normal;
}
.actionRoom b {
  font-size: 1.8rem;
}
.actionRoom .odyssey-prices b {
  font-size: 1.2rem;
}

.sportspage_area label {
  font-size: 15px;
}
@media ( max-width: 768px) {
  .table-responsive {
    margin-bottom: 0px;
  }
}

@media (max-width: 479px) {
  .hot-flight {
    background-size: 20px 20px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
  }
  .flight-select {
    top: calc( 50% - 10px ) !important;
  }

  .flight-img{
    padding-right: 15px;
  }
  .ticket-topPart-big, .ticket-bottomPart-big{
    padding-left: 0px;
    padding-right: 0px;
  }
  .actionRoom{
    flex-direction: row;
    justify-content: space-between;
  }
  .actionRoom .itemTags{
    display: block;
    line-height: 14px;
    text-align: right;
  }
  .mobile-facilities{
    width: 65%;
  }
  .share-button, .select-fav {
    transform: scale(0.7);
  }
  .share-button {
    right: 30px;
  }
}

.share-button {
  top: 2px;
}
</style>
<style>

.imgRoom .main-desc{
  margin-top: 10px !important;
}
.flight-select .custom-control {
  padding-left: 0;
}
.flight-select .custom-control-label {
  padding-right: 1.2rem;
}
.flight-select .custom-control-label::before,
.flight-select .custom-control-label::after {
  right: 0;
}

.package-organize .custom_tooltip {
  position: unset !important;
}
</style>
